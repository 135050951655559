import { Input } from "antd";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { brandActionCreators } from "../redux/seller";
import { SearchOutlined } from "@ant-design/icons";

const { Search } = Input;

const SearchSeller = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { searchBrand } = bindActionCreators(brandActionCreators, dispatch);

  const { info } = useSelector((state) => state.user);
  const { searchResult, showResult } = useSelector((state) => state.brands);

  const onSearch = async (value) => {
    searchBrand(info.token, { keyword: value });
  };

  return (
    <div style={{ width: "50%", height: "48px", margin: "16px 0px 24px" }}>
      <Search
        className="search"
        allowClear
        enterButton="Search"
        onSearch={onSearch}
        placeholder="Enter Seller Name"
        prefix={<SearchOutlined />}
      />

      {showResult ? (
        searchResult.length > 0 ? (
          <div className="search-result-container">
            {searchResult.map((brand) => (
              <p
                className="txt-search"
                onClick={() =>
                  history.replace(
                    `/seller/${brand.seller_id}/${brand.slug}`,
                    brand
                  )
                }
              >
                {brand.store_name}
              </p>
            ))}
          </div>
        ) : (
          <p className="txt-search">No results found</p>
        )
      ) : (
        ""
      )}

      <style jsx={true}>{`
        .ant-input-search
          > .ant-input-group
          > .ant-input-group-addon:last-child
          .ant-input-search-button {
          background: #000;
          border: 1px solid #000;
          color: #fff;
          height: 48px;
          font-family: "DomaineSansText-Light";
          padding: 0 24px;
        }
        .ant-input-search
          .ant-input-group
          .ant-input-affix-wrapper:not(:last-child) {
          height: 48px;
          border-color: black;
        }
        .search-result-container {
          box-shadow: 0px 3px 10px rgb(0 0 0 / 0.2);
          width: 40%;
          max-height: 200px;
          position: absolute;
          padding-left: 10px;
          background: #fff;
          overflow: scroll;
          z-index: 100;
        }
        .txt-search {
          color: #000;
          cursor: pointer;
          font: normal normal 14px/146.16% DomaineSansText-Regular;
          margin: 0px;
          transition: box-shadow 0.5s;
          padding: 8px;
        }
        .txt-search:hover {
          box-shadow: 0px 3px 10px 5px rgb(0 0 0 / 0.2);
        }
      `}</style>
    </div>
  );
};

export default SearchSeller;
